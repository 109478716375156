import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index.vue')
    },
    {
        path: '/index2',
        name: 'index2',
        component: () => import('../views/index2.vue')
    },
    {
        path: '/index4',
        name: 'index4',
        component: () => import('../views/index4.vue')
    }
]
const router = new VueRouter({
    mode: "history",
    routes
})

export default router