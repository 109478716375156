import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

//引入dataV组件库
import dataV from '@jiaminghi/data-view';

Vue.use(dataV);

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';

// 引入全局css
import './assets/scss/style.scss';


//引入echart
import echarts from 'echarts'

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;


// 引入 axios
import Axios from 'axios'

Vue.config.productionTip = false

Vue.prototype.$axios = Axios
//testAdd
//Axios.defaults.baseURL = 'http://localhost:8080/openApi/bigScreen/'
//Axios.defaults.baseURL = 'http://192.168.1.11:8080/openApi/getDataPool'
Axios.defaults.baseURL = 'http://47.92.33.161:80/openApi/zcqh/dataScreen/dataPool'
Axios.defaults.zcbaseURL = 'http://tsms.sd.zechengsk.com/openApi/zcqh/dataScreen/dataPool'

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
